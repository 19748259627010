'use client';

import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Box, Container, Button, Typography, Paper, TextField, IconButton, Alert, Snackbar } from '@mui/material';
import Link from 'next/link';
import { signInWithGoogle, useAuthState, signIn, sendPasswordReset } from '../lib/firebase/app';
import { Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinkWithLoading from '../components/link_with_loading';

const LoginPage = () => {
  const router = useRouter();
  const { user, loading } = useAuthState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [resetError, setResetError] = useState('');

  useEffect(() => {
    if (user && !loading) {
      router.push('/profile');
    }
  }, [user, loading, router]);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      router.push('/profile');
    } catch (error) {
      console.error('Google Sign-In error:', error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleEmailPasswordSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await signIn(email, password);
      if (result) {
        router.push('/profile');
      } else {
        setError('Sign-in successful but user data is missing. Please try again.');
      }
    } catch (error) {
      console.error('Email/Password Sign-In error:', error);
      setError('Invalid email or password. Please try again.');
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setResetError('Please enter your email address first');
      return;
    }

    try {
      await sendPasswordReset(email);
      setResetSent(true);
      setResetError('');
    } catch (error) {
      console.error('Password reset error:', error);
      setResetError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#2c2c54',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <IconButton
        onClick={() => router.push('/')}
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          color: 'white',
          '&:hover': {
            color: '#FF4081',
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
          }
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Container component='main' maxWidth='sm'>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 3,
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
            backdropFilter: 'blur(4px)',
            border: '1px solid rgba(255, 255, 255, 0.18)',
          }}
        >
          <Typography
            component='h1'
            variant='h3'
            fontFamily="Thick"
            sx={{ color: 'white', marginBottom: 3, textAlign: 'center' }}
          >
            Venue Owner Portal
          </Typography>
          <Typography
            variant='body1'
            fontFamily="Sans"
            sx={{ color: '#AAAAAA', marginBottom: 3, textAlign: 'center' }}
          >
            Not a venue owner? Go back to the <LinkWithLoading href="/" style={{ color: '#FF4081' }}>homepage</LinkWithLoading> or view your previous <LinkWithLoading href="/bookings" style={{ color: '#FF4081' }}>bookings</LinkWithLoading>
          </Typography>
          <Box component="form" onSubmit={handleEmailPasswordSignIn} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
                shrink: true,
              }}
              InputProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&:hover fieldset': { borderColor: 'white' },
                  '&.Mui-focused fieldset': { borderColor: '#FF4081' },
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 1000px #2c2c54 inset',
                  WebkitTextFillColor: 'white',
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                style: { color: 'white' },
                shrink: true,
              }}
              InputProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                  '&:hover fieldset': { borderColor: 'white' },
                  '&.Mui-focused fieldset': { borderColor: '#FF4081' },
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 1000px #2c2c54 inset',
                  WebkitTextFillColor: 'white',
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Button
                onClick={handlePasswordReset}
                sx={{
                  color: '#FF4081',
                  fontFamily: 'Sans',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 64, 129, 0.1)',
                  }
                }}
              >
                Forgot password?
              </Button>
            </Box>
            {error && (
              <Typography color="#FF4081" sx={{ mt: 2, textAlign: 'center', fontFamily: 'Sans' }}>
                {error}
              </Typography>
            )}
            {resetError && (
              <Typography color="#FF4081" sx={{ mt: 1, textAlign: 'center', fontFamily: 'Sans' }}>
                {resetError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#FF4081',
                '&:hover': { backgroundColor: '#F50057' },
                fontFamily: 'Sans'
              }}
            >
              Sign In
            </Button>
            <Divider sx={{ my: 1 }}>
              <Typography variant="body2" sx={{ px: 2, color: 'white' }}>
                OR
              </Typography>
            </Divider>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignIn}
              sx={{
                mt: 1,
                mb: 2,
                color: 'white',
                borderColor: 'white',
                '&:hover': {
                  borderColor: '#FF4081',
                  color: '#FF4081',
                  backgroundColor: 'rgba(255, 64, 129, 0.1)'
                },
                fontFamily: 'Sans'
              }}
            >
              Sign In with Google
            </Button>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={resetSent}
        autoHideDuration={6000}
        onClose={() => setResetSent(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setResetSent(false)}
          severity="success"
          sx={{
            width: '100%',
            backgroundColor: 'rgba(76, 175, 80, 0.9)',
            color: 'white',
            fontFamily: 'Sans'
          }}
        >
          Password reset email sent. Please check your inbox.
        </Alert>
      </Snackbar>
    </Box >
  );
};

export default LoginPage;
